import React from 'react'
import './VideoStyles.css'
import backgroundImage from '../assets/f117.png'
import { Link } from 'react-router-dom';

function Video() {
  return (
    <div className='hero' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='content'>
        <h1>Open AFI</h1>
        <p>Putting the AI in AFI</p>
        <div className='button-group'>
          <Link to='/Info' className='btn'>Info</Link>
          <a href="https://chat.openafi.com" className='btn'><b>LAUNCH</b></a>
        </div>
      </div>
    </div>
  );
}

export default Video;